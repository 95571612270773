define("discourse/plugins/discourse-signatures/discourse/templates/connectors/user-custom-preferences/signature-preferences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.signatures_enabled}}
    <div class="control-group signatures">
      <label class="control-label">{{i18n "signatures.enable_signatures"}}</label>
      <div class="controls">
        <label class="checkbox-label">
          <Input @type="checkbox" @checked={{this.model.see_signatures}} />
          {{i18n "signatures.show_signatures"}}
        </label>
      </div>
    </div>
    <div class="control-group signatures">
      <label class="control-label">{{i18n "signatures.my_signature"}}</label>
      {{#if this.siteSettings.signatures_advanced_mode}}
        <DEditor
          @value={{this.model.custom_fields.signature_raw}}
          @showUploadModal="showUploadModal"
        />
      {{else}}
        <Input
          @type="text"
          class="input-xxlarge"
          placeholder={{i18n "signatures.signature_placeholder"}}
          @value={{this.model.custom_fields.signature_url}}
        />
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "QXWkegu4",
    "block": "[[[41,[30,0,[\"siteSettings\",\"signatures_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group signatures\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,3],[\"signatures.enable_signatures\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"model\",\"see_signatures\"]]]],null],[1,\"\\n        \"],[1,[28,[35,3],[\"signatures.show_signatures\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group signatures\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,3],[\"signatures.my_signature\"],null]],[13],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"signatures_advanced_mode\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@value\",\"@showUploadModal\"],[[30,0,[\"model\",\"custom_fields\",\"signature_raw\"]],\"showUploadModal\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],[[24,0,\"input-xxlarge\"],[16,\"placeholder\",[28,[37,3],[\"signatures.signature_placeholder\"],null]]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"model\",\"custom_fields\",\"signature_url\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"label\",\"i18n\",\"input\",\"d-editor\"]]",
    "moduleName": "discourse/plugins/discourse-signatures/discourse/templates/connectors/user-custom-preferences/signature-preferences.hbs",
    "isStrictMode": false
  });
});